/**
 * This function allow you to modify a JS Promise by adding some status properties.
 * Based on: http://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
 * But modified according to the specs of promises : https://promisesaplus.com/
 */

/**
 * @typedef QueryablePromise
 * @extends Promise
 */

/**
 *
 * @param {Promise} promise
 * @return {QueryablePromise}
 */
function makeQueryablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isFulfilled) return promise;

  // Observe the promise, saving the fulfillment in a closure scope.
  let result = promise.then(
    function (v) {
      result.isFulfilled = true;
      result.isPending = false;
      return v;
    },
    function (e) {
      result.isRejected = true;
      result.isPending = false;
      throw e;
    }
  );

  // Set initial state
  result.isPending = true;
  result.isRejected = false;
  result.isFulfilled = false;

  return result;
}

module.exports = {
  makeQueryablePromise: makeQueryablePromise,
};
